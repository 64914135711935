import Vue from 'vue';

var app = new Vue({

	el: '#app',

	data: {
		topFixedMenuVisible: false,
		contato: {
			email: null,
			telefone: null,
			mensagem: null,
		},

		works: {
			creation: {
				image: 'ideia', 
				title: 'CRIAÇÃO',
				data: ['identidade-visual', 'carflix', 'coding-craft', 'swood']
			},
			web: {
				image: 'ideia',
				title: 'WEB', 
				data: ['carflix', 'coding-craft', 'swood', 'identidade-visual']
			},
			systems: {
				image: 'ideia', 
				title: 'SISTEMAS',
				data: ['identidade-visual', 'carflix', 'swood', 'coding-craft']
			},
			education: {
				image: 'computador', 
				title: 'EDUCAÇÃO',
				data: ['identidade-visual', 'coding-craft', 'swood', 'carflix']
			},	
		},

		currentWork: 'creation',

		companies: [
			'tudo-radio',
			'itti',
			'tha',
			'espasso',
			'havaianas',
			'manocchio',
			'orthodontix',
			'canibuild',
			'gra-dias',
			'crivelli',
			'carflix',
			'mission-command'
		],

		team: [
			{photo: 'leonel', name: 'LEONEL SANCHES DA SILVA', role: 'SÓCIO / DESENVOLVEDOR', linkedin: 'https://www.linkedin.com/in/leonelsanchesdasilva/'},
			{photo: 'leandro', name: 'LEANDRO SANCHES DA SILVA', role: 'SÓCIO / DESENVOLVEDOR', linkedin: 'https://www.linkedin.com/in/leandro-sanches-da-silva/'},
			{photo: 'renilson', name: 'RENILSON ANDRADE MENEGUCI', role: 'SÓCIO / DESENVOLVEDOR', linkedin: 'https://www.linkedin.com/in/renilson-meneguci/'}
		],
	},

	/* methods: {

		showTeamMember: function (member) {

			var modal = new this.WMModal({
				templateUrl: '/views/modal-team-member.html',
				containerClass: 'modal-team-member',
				data: {
					member: member
				},
			});
		}
	} */
})