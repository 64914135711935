import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

// window.WMModal && Vue.use(WMModal);

Vue.component('dl-navbar-fixed', function dlNavbarFixed(resolve, reject) {  

    Vue.http.get('/views/navbar-fixed.html').then(function(response) {

        resolve({
            template: response.body,
            props: {
                offset: Number,
                activeItem: {type: Number, required: true},
            },

            data: function () {
                return {
                    activeItem: null,
                    isVisible: false,
                }
            },

            mounted: function () {
                window.addEventListener('scroll', function () {
                    this.isVisible = window.scrollY > this.offset;
                }.bind(this))
            }
        });
    });
});

Vue.component('dl-navbar', function (resolve, reject) {

     Vue.http.get('/views/navbar.html').then(function(response) {

        resolve({
            template: response.body,

            props: {
                activeItem: {type: Number, required: true}
            },

            data: function () {
                return {
                    activeItem: null,
                    items: [
                        {
                            name: 'SERVIÇOS',
                            url : '/#servicos'
                        },
                        {
                            name: 'SOBRE NÓS',
                            url : '/#sobre-nos'
                        },
                        {
                            name: 'TRABALHOS',
                            url : '/#trabalhos'
                        },
                        {
                            name: 'EQUIPE',
                            url : '/#equipe'
                        }
                    ]
                }
            }
        })
     });
});

Vue.component('dl-navbar-mobile', function (resolve, reject) {

    Vue.http.get('/views/navbar-mobile.html').then(function(response) {

        resolve({
            template: response.body,
            props: {
                offset: Number,
                activeItem: {type: Number, required: true},
            },

            data: function () {
                return {
                    activeItem: null,
                    isVisible: false,
                }
            }
        });
    });
});
/** HEADER Completo  para algumas páginas **/
Vue.component('dl-top-header', function (resolve) {

     Vue.http.get('/views/top-header.html').then(function(response) {

        resolve({
           template: response.body,
            props: {
                activeItem: {
                    type: Number,
                    required: true,
                },
            }
        })
     });
})

Vue.component('dl-icon-arrow', function (resolve) {

    Vue.http.get('/views/seta.svg.html').then(function (response) {

        resolve({
            template: response.body,
            props: {
                width: Number,
                iconClass : String
            }
        })

    })
})

Vue.component('dl-footer', function (resolve, reject) {

     Vue.http.get('/views/footer.html').then(function(response) {

        resolve({
            template: response.body,

            data: function () {
                return {
                    newsletter: {
                        email: null,
                    }
                }
            },

            methods: {

                sendNewsLetter: function () {

                    Vue.http.post('/api/newsletter').then(function (response) {
                        this.newsletter.email = null;
                    });
                }
            }
        })
     });
});

Vue.component('dl-header-blog', function dlHeaderBlog(resolve, reject) {

    function callback (template) {

        return resolve({
            template: template,
            props: ['value'],

            methods: {
                debounceSearch: function search() {

                    var self = this;

                    clearTimeout(search.timeout);

                    search.timeout = setTimeout(function () { 
                        self.$emit('input', this.value);
                    }, 500);
                },

                selectCategory: function (category) {

                    this.value.categoryId = category.id;

                    this.$emit('input', this.value);
                }
            },

            data: function () {
                return {
                    categories: [
                        {id: 1, image: '/img/icones/ideia.png', title: 'Criação'},
                        {id: 2, image: '/img/icones/computador.png', title: 'WEB'},
                        {id: 3, image: '/img/icones/globalizacao.png', title: 'SISTEMAS'},
                        {id: 4, image: '/img/icones/checklist.png', title: 'EDUCAÇÃO'}
                    ]
                }
            }
        })
    }

    // Cache
    if (dlHeaderBlog.cacheBody) 
    {
        return callback(dlHeaderBlog.cacheBody);
    }

    Vue.http.get('/views/header-blog.html').then(function (response) {
        dlHeaderBlog.cacheBody = response.body;
        callback(response.body);
    })
});

Vue.component('dl-contact', function dlHeaderBlog(resolve, reject) {

    Vue.http.get('/views/contact.html').then(function (response) {

        resolve({
            template: response.body,
        })
    })
})